@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:100,200,300,400,500);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body {
  height: 100%;
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  background-color: #393e46; }

body {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

#footer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 3em;
  background-color: #222831;
  color: #eeeeee;
  width: 100%;
  text-align: center;
  font-weight: 100;
  z-index: 1000; }

#footer-text {
  font-weight: 300;
  color: #eeeeee; }

.healtcheck-item {
  background-color: #333;
  margin: 0.5em;
  border-radius: 8px 8px 8px 8px; }

.healthcheck-text {
  padding: 0.5em; }

#wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  position: absolute;
  width: 100%;
  top: 12%;
  left: 0; }

#info-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  max-width: 90%;
  padding-bottom: 5em; }

.status-up-box {
  -webkit-animation-name: fading-green;
          animation-name: fading-green;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  border-radius: 0px 0px 8px 8px;
  height: 1em;
  margin: 0; }

.status-down-box {
  -webkit-animation-name: fading-red;
          animation-name: fading-red;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  border-radius: 0px 0px 8px 8px;
  height: 1em;
  margin: 0; }

@-webkit-keyframes fading-circle-service-down {
  0% {
    background-color: #f84e61; }
  25% {
    background-color: #ec4a5d; }
  50% {
    background-color: #e0495c; }
  75% {
    background-color: #ec4a5d; }
  100% {
    background-color: #f84e61; } }

@keyframes fading-circle-service-down {
  0% {
    background-color: #f84e61; }
  25% {
    background-color: #ec4a5d; }
  50% {
    background-color: #e0495c; }
  75% {
    background-color: #ec4a5d; }
  100% {
    background-color: #f84e61; } }

@-webkit-keyframes fading-circle-service-up {
  0% {
    background-color: #53E867; }
  25% {
    background-color: #54EA7E; }
  50% {
    background-color: #70F482; }
  75% {
    background-color: #54EA7E; }
  100% {
    background-color: #53E867; } }

@keyframes fading-circle-service-up {
  0% {
    background-color: #53E867; }
  25% {
    background-color: #54EA7E; }
  50% {
    background-color: #70F482; }
  75% {
    background-color: #54EA7E; }
  100% {
    background-color: #53E867; } }

.circle-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 15em;
  height: 3em;
  z-index: 10; }

.healthcheck-content {
  width: 100%;
  height: auto; }

.healthcheck-status-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  bottom: 0px; }

.healtcheck-box {
  margin: 2em 1em 1em 1em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  justify-items: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: transparent;
  z-index: 50;
  position: relative;
  border-radius: 8px; }

.healthcheck-img {
  height: 50px;
  width: 50px;
  position: absolute;
  top: -31px;
  left: 38%;
  border: 4px solid transparent;
  border-radius: 50%;
  background-color: #eeeeee; }

.healthcheck-title {
  margin: 25px 0 0 0;
  text-align: center;
  font-weight: 300;
  padding-left: 1em;
  padding-right: 1em;
  color: #222831; }

.healthcheck-status {
  color: #222831;
  margin: 0;
  text-align: center;
  position: relative;
  bottom: -2em !important;
  font-size: 15px;
  font-weight: 300; }

.healthcheck-last-ping-title {
  color: #222831;
  text-align: center;
  margin: 10px 0 0 0;
  font-size: 15px;
  font-weight: 300;
  padding-left: 1em;
  padding-right: 1em; }

.healthcheck-last-ping-value {
  color: #222831;
  text-align: center;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  padding-left: 1em;
  padding-right: 1em; }

.healthcheck-description {
  color: #222831;
  text-align: center;
  margin: 10px 0 0 0;
  font-size: 15px;
  font-weight: 300;
  padding-left: 2em;
  padding-right: 2em; }

.circle-status {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }
  .circle-status--up {
    background-color: #58F46D; }
  .circle-status--down {
    background-color: #F84E61; }

.circle--sm {
  color: #333937;
  font-size: 3px;
  width: 4em;
  height: 4em;
  border-radius: 50%;
  position: absolute;
  content: "";
  bottom: 0;
  right: 0;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.09), 0 0px 20px rgba(0, 0, 0, 0.23);
  z-index: 40;
  margin-bottom: 20px !important;
  margin-right: 20px !important; }

.circle--md {
  color: #333937;
  font-size: 6px;
  width: 4em;
  height: 4em;
  border-radius: 50%;
  position: absolute;
  content: "";
  bottom: 0;
  right: 0;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.09), 0 0px 10px rgba(0, 0, 0, 0.25);
  z-index: 30;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
  margin-bottom: 14px !important;
  margin-right: 14px !important; }

.circle--xl {
  color: #333937;
  font-size: 9px;
  width: 4em;
  height: 4em;
  border-radius: 50%;
  position: absolute;
  content: "";
  bottom: 0;
  right: 0;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.09), 0 0px 5px rgba(0, 0, 0, 0.2);
  z-index: 20;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
  margin-right: 8px !important;
  margin-bottom: 8px !important; }

.flip {
  position: relative; }
  .flip > .front,
  .flip > .back {
    display: block;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-duration: 0.5s;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-property: transform; }
  .flip > .front {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  .flip > .back {
    position: absolute;
    opacity: 0;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
    padding-bottom: auto;
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-name: fadeOut;
            animation-name: fadeOut;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  .flip:hover > .front {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  .flip:hover > .back {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.flip {
  position: relative;
  width: 15em;
  height: 100%; }
  .flip > .front {
    background: #eeeeee;
    border-radius: inherit; }
  .flip > .back {
    background: #eeeeee;
    border-radius: inherit; }
  .flip:hover .healthcheck-img {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  .flip .healthcheck-img {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

#service-bar {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  color: #eeeeee;
  height: 3em;
  z-index: 100; }

#total-status-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: auto;
  background-color: #00adb5;
  width: 100%;
  font-weight: 800;
  z-index: 1000; }

#service-title {
  font-size: 1.5em;
  padding-right: 0.2em;
  font-weight: 500;
  text-transform: uppercase; }

.service-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  font-size: 1.2em;
  font-weight: 400;
  margin: 0;
  padding: 0; }

.service-number {
  margin: 0;
  padding: 0 0.4em 0 0.4em; }

@media only screen and (max-width: 600px) {
  #wrapper {
    top: 10%; }
  #service-title {
    padding-right: 0;
    font-size: 1em; }
  .service-info {
    padding: 0;
    font-size: 0.9em; }
  #footer-text {
    font-size: 0.8em; }
  #footer {
    height: 2em; } }

@media only screen and (max-width: 300px) {
  #service-title {
    padding-right: 0;
    font-size: 0.7em; }
  .service-info {
    padding: 0;
    font-size: 0.7em; } }

