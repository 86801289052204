@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:100,200,300,400,500');

html,body {
  height: 100%;
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  background-color: #393e46;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
}

#footer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 3em;
  background-color: #222831;
  color:#eeeeee;
  width: 100%;
  text-align: center;
  font-weight: 100;
  z-index: 1000;
}

#footer-text {
  font-weight: 300;
  color:#eeeeee;
}

.healtcheck-item {
  background-color: #333;
  margin: 0.5em;
  border-radius: 8px 8px 8px 8px;
}

.healthcheck-text {
  padding: 0.5em;
}

#wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  top:12%;
  left:0;
}

#info-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 90%;
  padding-bottom: 5em;
}

.status-up-box {
  animation-name: fading-green;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  border-radius: 0px 0px 8px 8px;
  height: 1em;
  margin: 0;
}

.status-down-box {
  animation-name: fading-red;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  border-radius: 0px 0px 8px 8px;
  height: 1em;
  margin: 0;
}

@keyframes fading-circle-service-down {
  0% {
    background-color: #f84e61;
  }
  25% {
    background-color: #ec4a5d;
  }
  50% {
    background-color: #e0495c;
  }
  75% {
    background-color: #ec4a5d;
  }
  100% {
    background-color: #f84e61;
  }
}

@keyframes fading-circle-service-up {
  0% {
    background-color: #53E867;
  }
  25% {
    background-color: #54EA7E;
  }
  50% {
    background-color: #70F482;
  }
  75% {
    background-color: #54EA7E;
  }
  100% {
    background-color: #53E867;
  }
}

.circle-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15em;
  height: 3em;
  z-index: 10;
}

.healthcheck-content {
  width: 100%;
  height: auto;
}

.healthcheck-status-container {
  display: flex;
  flex-direction: column;
  bottom: 0px;
}

.healtcheck-box {
  margin: 2em 1em 1em 1em;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  background-color: transparent;
  z-index: 50;
  position: relative;
  border-radius: 8px;
}

.healthcheck-img {
  height: 50px;
  width: 50px;
  position: absolute;
  top: -6-25px;
  left: 38%;
  border: 4px solid transparent;
  border-radius: 50%;
  background-color: #eeeeee;
}

.healthcheck-title {
  margin: 25px 0 0 0;
  text-align: center;
  font-weight: 300;
  padding-left:1em;
  padding-right:1em;
  color: #222831;
}

.healthcheck-status {
  color: #222831;
  margin: 0;
  text-align: center;
  position: relative;
  bottom: -2em !important;
  font-size: 15px;
  font-weight: 300;
}

.healthcheck-last-ping-title {
  color: #222831;
  text-align: center;
  margin: 10px 0 0 0;
  font-size: 15px;
  font-weight: 300;
  padding-left:1em;
  padding-right:1em;
}

.healthcheck-last-ping-value {
  color: #222831;
  text-align: center;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  padding-left:1em;
  padding-right:1em;
}

.healthcheck-description {
  color: #222831;
  text-align: center;
  margin: 10px 0 0 0;
  font-size: 15px;
  font-weight: 300;
  padding-left:2em;
  padding-right:2em;
}

// Mixin
@mixin circle-btn($font-size, $padding-circles){
  color:#333937;
  font-size: $font-size;
  width:4em;
  height:4em;
  border-radius: 50%;
  position:absolute;
  content: "";
  bottom:0;
  right:0;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}


$padding-circles: 8px;

.circle-status {
  //Settings to reduce CPU usage
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);;
  &--up {
    //animation-name: fading-circle-service-up;
    background-color: #58F46D;
  }
  &--down {
    //animation-name: fading-circle-service-down;
    background-color: #F84E61;
  }
}

// Styling
.circle--sm{
  @include circle-btn(3px, $padding-circles);
  box-shadow: 0 0px 0px rgba(0,0,0,0.09), 0 0px 20px rgba(0,0,0,0.23);
  z-index: 40;
  margin-bottom: 12 + $padding-circles !important;
  margin-right: 12 + $padding-circles !important;

}
.circle--md{
  @include circle-btn(6px, $padding-circles);
  box-shadow: 0 0px 0px rgba(0,0,0,0.09), 0 0px 10px rgba(0,0,0,0.25);
  z-index: 30;
  animation-delay: 0.2s;
  margin-bottom: 6 + $padding-circles !important;
  margin-right: 6 + $padding-circles !important;
}
.circle--xl{
  @include circle-btn(9px, $padding-circles);
  box-shadow: 0 0px 0px rgba(0,0,0,0.09), 0 0px 5px rgba(0,0,0,0.20);
  z-index: 20;
  animation-delay: 0.4s;
  margin-right: $padding-circles !important;
  margin-bottom: $padding-circles !important;
}

// base
.flip {
  position: relative;
  >.front,
  >.back {
    display: block;
    transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
    transition-duration: 0.5s;
    transition-property: transform;
    -webkit-transition-property: transform;
  }
  >.front {
    transform: rotateY(0deg);
  }
  >.back {
    position: absolute;
    opacity: 0;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transform: rotateY(-180deg);
    padding-bottom: auto;
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    animation-name: fadeOut;
    animation-fill-mode: forwards;
  }
  &:hover {
    >.front {
      transform: rotateY(180deg);
    }
    >.back {
      opacity: 1;
      transform: rotateY(0deg);
      animation-duration: 0.2s;
      animation-iteration-count: 1;
      animation-name: fadeIn;
      animation-fill-mode: forwards;
    }
  }
}

@-webkit-keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-webkit-keyframes fadeOut {
  from { opacity: 1; }
  to   { opacity: 0; }
}
@keyframes fadeOut {
  from { opacity: 1; }
  to   { opacity: 0; }
}


// custom
.flip {
  position: relative;
  width: 15em;
  height: 100%;
  >.front {
    background: #eeeeee;
    border-radius: inherit;
  }
  >.back {
    background: #eeeeee;
    border-radius: inherit;
  }
  &:hover .healthcheck-img {
    transform: rotateY(180deg);
  }
  .healthcheck-img {
    transform: rotateY(0);
    transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
  }
}

#service-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color:#eeeeee;
  height:3em;
  z-index: 100;
}

#total-status-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: auto;
  background-color: #00adb5;
  width: 100%;
  font-weight: 800;
  z-index: 1000;
}

#service-title {
  font-size: 1.5em;
  padding-right: 0.2em;
  font-weight: 500;
  text-transform: uppercase;
}
.service-info {
  display:flex;
  flex-direction: row;
  font-size: 1.2em;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.service-number {
  margin: 0;
  padding: 0 0.4em 0 0.4em;
}

@media only screen and (max-width: 600px) {
  #wrapper {
    top:10%;
  }

  #service-title {
    padding-right: 0;
    font-size: 1em;
  }
  .service-info {
    padding: 0;
    font-size: 0.9em;
  }

  #footer-text {
    font-size: 0.8em;
  }

  #footer {
    height: 2em;
  }
}

@media only screen and (max-width: 300px) {

  #service-title {
    padding-right: 0;
    font-size: 0.7em;
  }
  .service-info {
    padding: 0;
    font-size: 0.7em;
  }
}